<template>
  <div class="page">
    <div class="content">
      <div class="articles">
        <div class="addInput">
          <input v-model="keyword"/>
          <div @click="add" class="button" :class="{disabled:!keyword}">添加</div>
        </div>
        <ul class="list webs">
          <li v-for="(item,xh) in list" :key="xh" class="li">
            <a :href="item.url" target="_blank">
              <div class="title">{{item.typename}}</div>
              <div class="time"></div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {list, CheckCreate} from '@/api/api'
import $Toast from '@/plugin/LeeToast'

export default {
    name: 'articles',
    data() {
        return {
            list: [],
            keyword: ''
        }
    },
    created() {
        let data = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'webtype',
            perpage: 1000

        }
        list(data).then((rs) => {
            this.list = rs.data.list
        })
    },
    methods: {
        add() {
            let data = {
                app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
                model_name: 'webtype',
                check_field: 'typename',
                data: {
                    typename: this.keyword
                }

            }
            CheckCreate(data).then((rs) => {
                if (rs.data.err_code === 0) {
                    $Toast({
                        message: '添加成功',
                        type: 'success',
                    })
                }else{
                    $Toast({
                        message: rs.msg,
                        type: 'error',
                    })
                }

            })

        }
    },
    computed: {}
}
</script>

